import React from 'react';
import { InstantSearch, SearchBox, connectHits } from 'react-instantsearch-dom'

import './Theme.css'

//import NewItem from './NewItem'
import Card from './Card'

import searchClient from './algoliaClient'

const Hits = ({ hits }) => hits.map(hit => Card(hit))
const CustomHits = connectHits(Hits)

function App() {
  return (
    <InstantSearch
      indexName="searchDocs"
      searchClient={searchClient}
    >
      <section id="team" className="pb-5">
        <div className="container">
          <h5 className="section-title h1">Find Documents</h5>
          <div className="row" style={{ justifyContent: 'center', marginBottom: '2%' }}>
            <SearchBox
              autoFocus={true}
              translations={{
                submitTitle: 'Pesquisar',
                resetTitle: 'Limpar Pesquisa',
                placeholder: 'O que você procura?',
              }}
            />
          </div>
          <div className="row">
            <CustomHits />
          </div>
        </div>
      </section>
    </InstantSearch>
  )
}

export default App
