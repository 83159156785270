import React from 'react'
import TextTruncate from 'react-text-truncate'

import pdfIcon from './pdfIcon.png'

const Card = hit => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={hit.objectID}>
        <div className="image-flip" ontouchstart="this.classList.toggle('hover');">
            <div className="mainflip">
                <div className="frontside">
                    <div className="card">
                        <div className="card-body text-center">
                            <p><img className=" img-fluid" src={pdfIcon} alt="Pdf Icon" /></p>
                            <h4 className="card-title">{hit.name}</h4>
                            <p className="card-text">{hit.referencia}</p>
                            <span href="#" className="btn btn-primary btn-sm"><i className="fa fa-plus"></i></span>
                        </div>
                    </div>
                </div>
                <div className="backside">
                    <div className="card">
                        <div className="card-body text-center mt-4">
                            <h4 className="card-title">{hit.name}</h4>
                            <p className="card-text">
                                <TextTruncate
                                    line={6}
                                    element="span"
                                    truncateText="…"
                                    text={hit.introducao}
                                    textTruncateChild={
                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target={`#${hit.objectID}`}>
                                            Ver Documento
                                        </button>
                                    }
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id={hit.objectID} tabIndex="-1" role="dialog" aria-labelledby={hit.name} aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={hit.name}>{hit.name}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {hit.introducao}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
)

export default Card